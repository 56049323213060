<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-01 22:37:35
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-16 21:54:41
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\riskAnalyseRule\Index.vue
-->
<template>
  <div class="container">
    <a-row :gutter="20">
      <a-col :span="12">
        <div class="wrapper">
          <div style="margin-bottom: 10px">
            <a-button type="primary" icon="copy" @click="handleCopy"
              >复制指标</a-button
            >
          </div>
          <a-spin :spinning="loading" class="tree">
            <a-tree
              checkable
              show-icon
              :blockNode="true"
              :tree-data="treeData"
              :expandedKeys.sync="expandedKeys"
              :checkedKeys.sync="checkedKeys"
              :replaceFields="{
                children: 'children',
                title: 'menuName',
                key: 'id',
                value: 'id',
              }"
              @check="onCheck"
              @select="onSelect"
            >
              <template slot="custom" slot-scope="item">
                <div class="node">
                  <span class="node-title"
                    ><a-icon
                      type="folder-open"
                      v-if="expandedKeys.includes(item.id)"
                    />
                    <a-icon type="file-text" v-else-if="item.type == 8" />
                    <a-icon type="folder" v-else />
                    {{ item.menuName }}
                  </span>
                  <span class="icon-wrap" v-if="item.type == 8">
                    <a-tooltip>
                      <template slot="title">删除</template>
                      <a-icon type="delete" @click="handleDelete(item)" />
                    </a-tooltip>
                  </span>
                  <span class="icon-wrap" v-if="item.type == 8">
                    <a-tooltip>
                      <template slot="title">编辑</template>
                      <a-icon type="form" @click.stop="handleEdit(item)" />
                    </a-tooltip>
                  </span>
                  <span class="icon-wrap" v-if="item.type == 4">
                    <a-tooltip>
                      <template slot="title">新建风险分析指标</template>
                      <a-icon type="plus" @click="handleAdd(item.id)" />
                    </a-tooltip>
                  </span>
                </div>
              </template>
            </a-tree>
          </a-spin>
        </div>
      </a-col>
      <a-col :span="12">
        <a-card title="风险分析指标详情" :bordered="false" v-if="detailVisible">
          <a slot="extra" href="#"><a-icon type="question-circle" /></a>
          <a-descriptions title="所属目录" :column="4">
            <a-descriptions-item label="年份">{{
              cataInfo.year
            }}</a-descriptions-item>
            <a-descriptions-item label="纳税人类型">{{
              cataInfo.taxpayer
            }}</a-descriptions-item>
            <a-descriptions-item label="指标准则类型">{{
              cataInfo.type
            }}</a-descriptions-item>
            <a-descriptions-item label="时间类型">{{
              cataInfo.dateType
            }}</a-descriptions-item>
          </a-descriptions>
          <a-descriptions title="指标信息" :column="4">
            <a-descriptions-item label="指标名称">{{
              tableInfo.indexName
            }}</a-descriptions-item>
            <a-descriptions-item label="指标类别">{{
              tableInfo.indexLevel
            }}</a-descriptions-item>
            <a-descriptions-item label="计算规则" span="2">{{
              tableInfo.formula
            }}</a-descriptions-item>
            <a-descriptions-item label="低风险说明">{{
              tableInfo.lowRiskDesc
            }}</a-descriptions-item>
            <a-descriptions-item label="中风险说明">{{
              tableInfo.midRiskDesc
            }}</a-descriptions-item>
            <a-descriptions-item label="高风险说明" span="2">{{
              tableInfo.higRiskDesc
            }}</a-descriptions-item>
            <a-descriptions-item label="风险应对" span="4">{{
              tableInfo.riskTreatment
            }}</a-descriptions-item>
            <a-descriptions-item label="风险指向">{{
              tableInfo.riskTrend
            }}</a-descriptions-item>
            <a-descriptions-item label="风险提示">{{
              tableInfo.riskWarning
            }}</a-descriptions-item>
          </a-descriptions>
          <a-descriptions
            title="特殊时间取值"
            :column="4"
            v-if="specialTimeVo != null"
          >
            <a-descriptions-item label="开始时间">{{
              formatSpecialTime(specialTimeVo.beginDate)
            }}</a-descriptions-item>
            <a-descriptions-item label="结束时间" span="3">{{
              formatSpecialTime(specialTimeVo.endDate)
            }}</a-descriptions-item>
          </a-descriptions>
          <a-descriptions title="字段信息" :column="3"> </a-descriptions>
          <a-table :columns="columns" :data-source="data" :pagination="false">
            <span slot="dateType" slot-scope="dateType">
              {{ formatDateType(dateType) }}
            </span>
            <span slot="indexResultTime" slot-scope="indexResultTime">
              {{ formatIndexTime(indexResultTime) }}
            </span>
          </a-table>
          <a-descriptions
            style="margin-top: 20px"
            title="风险判断范围"
            :column="3"
          >
          </a-descriptions>
          <a-table :columns="columns1" :data-source="data1" :pagination="false">
            <span slot="riskType" slot-scope="riskType">
              {{ riskTypeFormatter(riskType) }}
            </span>
          </a-table>
          <div v-if="data2.length != 0">
            <a-descriptions
              style="margin-top: 20px"
              title="风险判断特殊条件"
              :column="3"
            >
            </a-descriptions>
            <a-table
              :columns="columns2"
              :data-source="data2"
              :pagination="false"
            >
              <span slot="riskType" slot-scope="riskType">
                {{ riskTypeFormatter(riskType) }}
              </span>
              <span slot="conditionSyb" slot-scope="conditionSyb">
                {{ formatConditionSyb(conditionSyb) }}
              </span>
            </a-table>
            <div :key="i" v-for="(item, i) in data2">
              <a-descriptions
                style="margin-top: 20px"
                :title="'风险判断特殊条件字段表' + (i + 1)"
                :column="3"
              >
              </a-descriptions>
              <a-table
                :columns="columns3"
                :data-source="item.calRuleSpecialTabDetailVos"
                :pagination="false"
              >
                <span slot="dateType" slot-scope="dateType">
                  {{ formatDateType(dateType) }}
                </span>
              </a-table>
            </div>
          </div>
        </a-card>
        <div class="empty" v-else>
          <a-empty />
        </div>
      </a-col>
    </a-row>
    <RuleModal ref="ruleModal" @reload="queryList"></RuleModal>
    <TreeModal ref="treeModal" @reload="queryList"></TreeModal>
  </div>
</template>
<script>
import { queryAllCatalogmenu } from "@/api/cata";
import { getRiskdetail, delRiskRule } from "@/api/risk";
import RuleModal from "./RuleModal.vue";
import TreeModal from "./TreeModal";
import { getParents, sortChinese } from "@/utils/utils";
import { riskType } from "@/utils/dict";
import pick from "lodash.pick";
const columns = [
  {
    title: "序号",
    dataIndex: "forColName",
    key: "forColName",
  },
  {
    title: "时间",
    dataIndex: "dateType",
    key: "dateType",
    scopedSlots: { customRender: "dateType" },
  },
  {
    title: "数据表",
    dataIndex: "tableCnName",
    key: "tableCnName",
  },
  // {
  //   title: "数据表1",
  //   dataIndex: "tableEnName",
  //   key: "tableEnName",
  // },
  {
    title: "对应列",
    dataIndex: "colCnName",
    key: "colCnName",
  },
  {
    title: "查询列值",
    key: "queryVal",
    dataIndex: "queryVal",
  },
  {
    title: "指标结果时间",
    key: "indexResultTime",
    dataIndex: "indexResultTime",
    scopedSlots: { customRender: "indexResultTime" },
  },
  {
    title: "指标结果名称",
    key: "indexResultName",
    dataIndex: "indexResultName",
  },
  {
    title: "经营信息名称",
    key: "bussInfoName",
    dataIndex: "bussInfoName",
  },
  {
    title: "风险描述名称",
    key: "riskDescName",
    dataIndex: "riskDescName",
  },
];
const columns1 = [
  {
    title: "风险类型",
    dataIndex: "riskType",
    key: "riskType",
    scopedSlots: { customRender: "riskType" },
  },
  {
    title: "风险范围",
    dataIndex: "riskRange",
    key: "riskRange",
  },
];
const columns2 = [
  {
    title: "分组",
    dataIndex: "sort",
    key: "sort",
  },
  {
    title: "风险类型",
    dataIndex: "riskType",
    key: "riskType",
    scopedSlots: { customRender: "riskType" },
  },
  {
    title: "计算公式",
    dataIndex: "formula",
    key: "formula",
  },
  {
    title: "多条件符号",
    key: "conditionSyb",
    dataIndex: "conditionSyb",
    scopedSlots: { customRender: "conditionSyb" },
  },
  {
    title: "判断条件",
    key: "jgdgeCondition",
    dataIndex: "jgdgeCondition",
  },
];
const columns3 = [
  {
    title: "序号",
    dataIndex: "forColName",
    key: "forColName",
  },
  {
    title: "时间",
    dataIndex: "dateType",
    key: "dateType",
    scopedSlots: { customRender: "dateType" },
  },
  {
    title: "数据表",
    dataIndex: "tableCnName",
    key: "tableCnName",
  },
  // {
  //   title: "数据表1",
  //   dataIndex: "tableEnName",
  //   key: "tableEnName",
  // },
  {
    title: "对应列",
    dataIndex: "colCnName",
    key: "colCnName",
  },
  {
    title: "查询列值",
    key: "queryVal",
    dataIndex: "queryVal",
  },
];
export default {
  name: "",
  components: { RuleModal, TreeModal },
  data() {
    return {
      loading: false,
      data: [],
      data1: [],
      data2: [],
      columns,
      columns1,
      columns2,
      columns3,
      cataInfo: {},
      tableInfo: {},
      treeData: [],
      expandedKeys: [],
      checkedKeys: [],
      checkedNodes: [],
      detailVisible: false,
      specialTimeVo: {},
    };
  },
  mounted() {
    this.queryList(1);
  },
  methods: {
    riskTypeFormatter(val) {
      return riskType.filter((item) => item.value == val)[0].label;
    },
    async queryList(initial) {
      this.loading = true;
      let res = await queryAllCatalogmenu({
        category: 2,
      });
      if (res.status == 200) {
        if (initial == 1) {
          this.expandedKeys = [];
        }
        let data = res.data;
        this.travelTree(data, initial);
        this.treeData = data;
      } else {
        this.$notification.error({
          message: "系统提示",
          description: res.msg || res.message,
          duration: 4,
        });
      }
      this.loading = false;
    },
    handleAdd(pid) {
      this.$refs.ruleModal.show(2, pid, getParents(this.treeData, pid));
    },
    handleEdit(item) {
      let pid = item.parentId;
      let detailId = item.detailId;
      this.$refs.ruleModal.show(
        3,
        pid,
        getParents(this.treeData, pid),
        detailId
      );
    },
    async handleCopy() {
      let len = this.checkedNodes.length;
      if (len < 1) {
        return this.$message.warning("请选择节点！");
      }
      this.$refs.treeModal.show(this.checkedNodes);
    },
    handleDelete(record) {
      const that = this;
      this.$confirm({
        title: "确认删除",
        content: "是否删除当前数据?",
        onOk: async function () {
          let params = Object.assign(
            {},
            pick(record, "detailId", "id", "menuName")
          );
          let res = await delRiskRule(params);
          if (res.status == 200) {
            that.$message.success("删除成功！");
            that.queryList();
          } else {
            that.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        },
      });
    },
    onCheck(checkedKeys, e) {
      this.checkedKeys = checkedKeys;
      let checkedNodes = e.checkedNodes;
      let nodes = [];
      checkedNodes.forEach((item) => {
        let node = item.data.props.dataRef;
        if (node.type == 8) nodes.push(item.data.props.dataRef);
      });
      this.checkedNodes = nodes;
    },
    async onSelect(keys, event) {
      let node = event.node.dataRef;
      if (node.type == 8) {
        this.detailVisible = true;
        let id = node.id;
        let parents = getParents(this.treeData, id);
        this.cataInfo = {
          year: parents[4].menuName,
          taxpayer: parents[3].menuName,
          type: parents[2].menuName,
          dateType: parents[1].menuName,
        };
        let detailId = node.detailId;
        let res = await getRiskdetail({ id: detailId });
        if (res.status == 200) {
          let calRuleMainVo = res.data.calRuleMainVo;
          this.tableInfo = calRuleMainVo;
          this.tableInfo.name = parents[0].menuName;
          this.data = res.data.calRuleTabDetailVos;
          this.data1 = res.data.calRuleRiskRangeVos;
          this.data2 = res.data.calRuleSpecialTabVos;
          this.specialTimeVo = res.data.specialTimeVo;
        } else {
          this.$notification.error({
            message: "系统提示",
            description: res.msg || res.message,
            duration: 4,
          });
        }
      } else {
        this.detailVisible = false;
      }
    },
    travelTree(datas, initial) {
      for (var i in datas) {
        let node = datas[i];
        node.scopedSlots = { title: "custom" };
        if (node.type < 3 && initial == 1) {
          this.expandedKeys.push(node.id);
        } else if (node.type == 4 && node.children) {
          sortChinese(node.children, "menuName");
        } else if (node.type > 5) {
          node.isLeaf = true;
        }
        if (datas[i].children) {
          this.travelTree(datas[i].children, initial);
        }
      }
    },
    formatDateType(value) {
      if (value !== null) {
        if (value == 0) {
          return "当前时间";
        }
        return "上一时间";
      }
      return value;
    },
    formatIndexTime(value) {
      if (value !== null) {
        if (value == 0) {
          return "当前";
        }
        return "上期";
      }
      return value;
    },
    formatConditionSyb(value) {
      if (value === 1) {
        return "||";
      } else if (value == 0) {
        return "&";
      }
    },

    formatSpecialTime(val) {
      let label = "";
      if (this.cataInfo.dateType == 1) {
        switch (val) {
          case 1:
            label = "一季度";
            break;
          case 2:
            label = "一季度";
            break;
          case 3:
            label = "一季度";
            break;
          case 4:
            label = "一季度";
            break;
        }
      } else {
        switch (val) {
          case 1:
            label = "一月";
            break;
          case 2:
            label = "二月";
            break;
          case 3:
            label = "三月";
            break;
          case 4:
            label = "四月";
            break;
          case 5:
            label = "五月";
            break;
          case 6:
            label = "六月";
            break;
          case 7:
            label = "七月";
            break;
          case 8:
            label = "八月";
            break;
          case 9:
            label = "九月";
            break;
          case 10:
            label = "十月";
            break;
          case 11:
            label = "十一月";
            break;
          case 12:
            label = "十二月";
            break;
        }
      }
      return label;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  min-height: 280px;
  .wrapper {
    padding: 20px;
    background: #fff;
    .tree {
      height: 930px;
      overflow-y: auto;
    }
    .node {
      .icon-wrap {
        display: none;
        float: right;
        margin-left: 5px;
      }
      &:hover {
        .icon-wrap {
          display: block;
        }
      }
    }
  }
}
.options {
  padding-bottom: 16px;
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 800px;
  background: #fff;
}
</style>
