<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-05 22:14:05
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-03 12:46:58
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\views\riskAnalyseRule\RuleModal.vue
-->
<template>
  <a-modal
    :title="title"
    :width="showSpecial ? 2200 : 1400"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleSave"
    @cancel="handleCancel"
  >
    <a-form :form="form" :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
      <a-row :gutter="20">
        <a-col :span="showSpecial ? 6 : 10">
          <a-descriptions title="基本信息" :column="3"> </a-descriptions>
          <a-form-item label="所属目录">
            <a-input v-decorator="['cata']" disabled />
          </a-form-item>
          <!-- <a-form-item label="规则名称">
            <a-input
              v-decorator="[
                'name',
                { rules: [{ required: true, message: '规则名称不能为空' }] },
              ]"
              placeholder="请输入规则名称"
            />
          </a-form-item> -->
          <a-form-item label="指标名称">
            <a-input
              v-decorator="[
                'indexName',
                { rules: [{ required: true, message: '指标名称不能为空' }] },
              ]"
              placeholder="请输入指标名称"
            />
          </a-form-item>
          <a-form-item label="指标类别">
            <a-select
              v-decorator="[
                'indexLevel',
                { rules: [{ required: true, message: '指标类别不能为空' }] },
              ]"
              placeholder="指标类别不能为空"
            >
              <a-select-option
                v-for="item in indexLevel"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="计算公式">
            <a-input
              v-decorator="[
                'formula',
                { rules: [{ required: true, message: '计算公式不能为空' }] },
              ]"
              placeholder="请输入计算公式"
            >
            </a-input>
          </a-form-item>
          <!-- <a-form-item label="无风险说明">
            <a-input
              v-decorator="[
                'noRiskDesc',
                { rules: [{ required: true, message: '无风险说明不能为空' }] },
              ]"
              placeholder="请输入无风险说明"
            />
          </a-form-item> -->
          <a-form-item label="低风险说明">
            <a-input
              v-decorator="[
                'lowRiskDesc',
                { rules: [{ required: true, message: '低风险说明不能为空' }] },
              ]"
              placeholder="请输入低风险说明"
            />
          </a-form-item>
          <a-form-item label="中风险说明">
            <a-input
              v-decorator="[
                'midRiskDesc',
                { rules: [{ required: true, message: '中风险说明不能为空' }] },
              ]"
              placeholder="请输入中风险说明"
            />
          </a-form-item>
          <a-form-item label="高风险说明">
            <a-input
              v-decorator="[
                'higRiskDesc',
                { rules: [{ required: true, message: '高风险说明不能为空' }] },
              ]"
              placeholder="请输入高风险说明"
            />
          </a-form-item>
          <a-form-item label="风险应对">
            <a-input
              v-decorator="[
                'riskTreatment',
                { rules: [{ required: true, message: '风险应对不能为空' }] },
              ]"
              placeholder="请输入风险应对"
            />
          </a-form-item>
          <a-form-item label="风险指向">
            <a-input
              v-decorator="[
                'riskTrend',
                { rules: [{ required: true, message: '风险指向不能为空' }] },
              ]"
              placeholder="请输入风险指向"
            />
          </a-form-item>
          <a-form-item label="风险提示">
            <a-input
              v-decorator="[
                'riskWarning',
                { rules: [{ required: true, message: '风险提示不能为空' }] },
              ]"
              placeholder="请输入风险提示"
            />
          </a-form-item>
          <a-descriptions
            title="特殊时间取值"
            :column="3"
            v-if="this.dateType != 0"
          >
          </a-descriptions>
          <a-form-item label="开始时间" v-if="this.dateType != 0">
            <a-select
              v-decorator="['beginDate']"
              placeholder="请选择开始时间"
              v-if="this.dateType == 1"
            >
              <a-select-option :value="1"> 一季度 </a-select-option>
              <a-select-option :value="2"> 二季度 </a-select-option>
              <a-select-option :value="3"> 三季度 </a-select-option>
              <a-select-option :value="4"> 四季度 </a-select-option>
            </a-select>
            <a-select
              v-decorator="['beginDate']"
              placeholder="请选择开始时间"
              v-else
            >
              <a-select-option :value="1"> 一月 </a-select-option>
              <a-select-option :value="2"> 二月 </a-select-option>
              <a-select-option :value="3"> 三月 </a-select-option>
              <a-select-option :value="4"> 四月 </a-select-option>
              <a-select-option :value="5"> 五月 </a-select-option>
              <a-select-option :value="6"> 六月 </a-select-option>
              <a-select-option :value="7"> 七月 </a-select-option>
              <a-select-option :value="8"> 八月 </a-select-option>
              <a-select-option :value="9"> 九月 </a-select-option>
              <a-select-option :value="10"> 十月 </a-select-option>
              <a-select-option :value="11"> 十一月 </a-select-option>
              <a-select-option :value="12"> 十二月 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="结束时间" v-if="this.dateType">
            <a-select
              v-decorator="['endDate']"
              placeholder="请选择结束时间"
              v-if="this.dateType == 1"
            >
              <a-select-option :value="1"> 一季度 </a-select-option>
              <a-select-option :value="2"> 二季度 </a-select-option>
              <a-select-option :value="3"> 三季度 </a-select-option>
              <a-select-option :value="4"> 四季度 </a-select-option>
            </a-select>
            <a-select
              v-decorator="['endDate']"
              placeholder="请选择结束时间"
              v-else
            >
              <a-select-option :value="1"> 一月 </a-select-option>
              <a-select-option :value="2"> 二月 </a-select-option>
              <a-select-option :value="3"> 三月 </a-select-option>
              <a-select-option :value="4"> 四月 </a-select-option>
              <a-select-option :value="5"> 五月 </a-select-option>
              <a-select-option :value="6"> 六月 </a-select-option>
              <a-select-option :value="7"> 七月 </a-select-option>
              <a-select-option :value="8"> 八月 </a-select-option>
              <a-select-option :value="9"> 九月 </a-select-option>
              <a-select-option :value="10"> 十月 </a-select-option>
              <a-select-option :value="11"> 十一月 </a-select-option>
              <a-select-option :value="12"> 十二月 </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="showSpecial ? 9 : 14">
          <a-descriptions title="字段信息" :column="3"> </a-descriptions>
          <div class="btns">
            <a-space>
              <a-button type="primary" icon="plus" @click="insertEvent(-1)"
                >新增字段</a-button
              >
              <a-button icon="delete" @click="removeEvent">删除选中</a-button>
            </a-space>
          </div>
          <a-alert
            style="margin-bottom: 10px"
            message="字段信息表中包含字段按顺序依次代表计算公式中所包含字段"
            type="warning"
            show-icon
          />
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            :data="tableData"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
          >
            <vxe-column type="checkbox" width="60"></vxe-column>
            <vxe-column
              field="forColName"
              title="序号"
              width="70"
              :edit-render="{ autofocus: '.vxe-input--inner' }"
            >
              <template #default="{ row }">
                <span>{{ row.forColName }}</span>
              </template>
              <template #edit="{ row }">
                <vxe-input
                  v-model="row.forColName"
                  type="text"
                  placeholder="请输入序号"
                ></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="dateType"
              title="时间"
              :edit-render="{ autofocus: '.vxe-input--inner' }"
            >
              <template #default="{ row }">
                <span>{{ formatDateType(row.dateType) }}</span>
              </template>
              <template #edit="{ row, $rowIndex }">
                <vxe-select
                  v-model="row.dateType"
                  transfer
                  @change="handleDateTypeChange($rowIndex, $event, 1, row)"
                >
                  <vxe-option :value="0" label="当前时间"></vxe-option>
                  <vxe-option :value="1" label="上一时间"></vxe-option>
                </vxe-select>
              </template>
            </vxe-column>
            <vxe-column field="tableCnName" title="数据表" :edit-render="{}">
              <template #edit="{ row, $rowIndex }">
                <vxe-select
                  v-model="row.tableCnName"
                  transfer
                  @change="handleTableChange($event, row, $rowIndex)"
                >
                  <vxe-option
                    v-for="item in tableList[$rowIndex]"
                    :key="item.tableCnName"
                    :value="item.tableCnName"
                    :label="item.tableCnName"
                  ></vxe-option>
                </vxe-select>
              </template>
            </vxe-column>
            <vxe-column field="tableEnName" title="表英文名" :visible="false">
            </vxe-column>
            <vxe-column
              field="colCnName"
              title="对应列"
              :edit-render="{ autofocus: '.vxe-input--inner' }"
            >
              <template #edit="{ row, $rowIndex }">
                <vxe-select
                  v-model="row.colCnName"
                  transfer
                  @change="handleColChange($event, row, $rowIndex)"
                >
                  <vxe-option
                    v-for="item in colList[$rowIndex]"
                    :key="item.colCnName"
                    :value="item.colCnName"
                    :label="item.colCnName"
                  ></vxe-option>
                </vxe-select>
              </template>
            </vxe-column>
            <vxe-column field="colEnName" title="列英文名" :visible="false">
            </vxe-column>
            <vxe-column field="queryVal" title="查询列值" :edit-render="{}">
              <template #edit="{ row }">
                <vxe-input
                  v-model="row.queryVal"
                  type="text"
                  placeholder="请输入查询列值"
                ></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="indexResultTime"
              title="指标结果时间"
              :edit-render="{ autofocus: '.vxe-input--inner' }"
            >
              <template #default="{ row }">
                <span>{{ formatIndexTime(row.indexResultTime) }}</span>
              </template>
              <template #edit="{ row }">
                <vxe-select v-model="row.indexResultTime" transfer>
                  <vxe-option :value="0" label="当前"></vxe-option>
                  <vxe-option :value="1" label="上期"></vxe-option>
                </vxe-select>
              </template>
            </vxe-column>
            <vxe-column
              field="indexResultName"
              title="指标结果名称 "
              :edit-render="{}"
            >
              <template #edit="{ row }">
                <vxe-input
                  v-model="row.indexResultName"
                  type="text"
                  placeholder="请输入指标结果名称"
                ></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="bussInfoName"
              title="经营信息名称 "
              :edit-render="{}"
            >
              <template #edit="{ row }">
                <vxe-input
                  v-model="row.bussInfoName"
                  type="text"
                  placeholder="请输入经营信息名称"
                ></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="riskDescName"
              title="风险描述名称 "
              :edit-render="{}"
            >
              <template #edit="{ row }">
                <vxe-input
                  v-model="row.riskDescName"
                  type="text"
                  placeholder="请输入风险描述名称"
                ></vxe-input>
              </template>
            </vxe-column>
            <vxe-column field="tabId" title="tabId" :visible="false">
            </vxe-column>
            <vxe-column field="sort" title="排序" :visible="false">
            </vxe-column>
            <vxe-column
              field="option"
              title="操作"
              :edit-render="{}"
              align="center"
            >
              <template #default="{ row }">
                <a href="javascript:;" @click="handleCopy(row)">复制</a>
              </template>
              <template #edit="{ row }">
                <a href="javascript:;" @click="handleCopy(row)">复制</a>
              </template>
            </vxe-column>
          </vxe-table>
          <a-descriptions
            title="风险判断范围"
            :column="3"
            style="margin-top: 20px"
          >
          </a-descriptions>
          <div class="btns">
            <a-space>
              <a-button type="primary" icon="plus" @click="insertEventRisk(-1)"
                >新增</a-button
              >
              <a-button icon="delete" @click="removeEventRisk"
                >删除选中</a-button
              >
            </a-space>
          </div>
          <vxe-table
            ref="xTableRisk"
            border
            resizable
            show-overflow
            :data="riskTableData"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
          >
            <vxe-column type="checkbox" width="60"></vxe-column>
            <vxe-column field="riskType" title="风险类型" :edit-render="{}">
              <template #default="{ row }">
                <span>{{ formatRiskType(row.riskType) }}</span>
              </template>
              <template #edit="{ row }">
                <vxe-select v-model="row.riskType" transfer>
                  <vxe-option
                    v-for="item in riskType"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></vxe-option>
                </vxe-select>
              </template>
            </vxe-column>
            <vxe-column
              field="riskRange"
              title="风险范围"
              :edit-render="{ autofocus: '.vxe-input--inner' }"
            >
              <template #edit="{ row }">
                <vxe-input
                  v-model="row.riskRange"
                  type="text"
                  placeholder="请输入风险范围"
                ></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="option"
              title="操作"
              :edit-render="{}"
              align="center"
            >
              <template #default="{ row }">
                <a href="javascript:;" @click="handleCopyRisk(row)">复制</a>
              </template>
              <template #edit="{ row }">
                <a href="javascript:;" @click="handleCopyRisk(row)">复制</a>
              </template>
            </vxe-column>
          </vxe-table>
          <div
            style="margin-top: 20px; display: flex; justify-content: flex-end"
          >
            <a
              href="javascript:;"
              @click="
                showSpecial = !showSpecial;
                specialFieldsLen = 0;
              "
              ><a-icon
                style="margin-right: 8px"
                :type="showSpecial ? 'minus' : 'plus'"
              />{{
                showSpecial ? "隐藏风险判断特殊条件" : "添加风险判断特殊条件"
              }}</a
            >
          </div>
        </a-col>
        <a-col :span="showSpecial ? 9 : 0" v-if="showSpecial">
          <a-descriptions
            title="风险判断特殊条件"
            :column="3"
            style="margin-top: 20px"
          >
          </a-descriptions>
          <div class="btns">
            <a-space>
              <a-button
                type="primary"
                icon="plus"
                @click="insertEventSpecial(-1)"
                >新增</a-button
              >
              <a-button icon="delete" @click="removeEventSpecial"
                >删除选中</a-button
              >
            </a-space>
          </div>
          <vxe-table
            ref="xTableSpecial"
            border
            resizable
            show-overflow
            :data="riskTableDataSpecial"
            :edit-config="{ trigger: 'click', mode: 'cell' }"
          >
            <vxe-column type="checkbox" width="60"></vxe-column>
            <vxe-column
              field="forColName"
              title="分组"
              width="100"
              :edit-render="{ autofocus: '.vxe-input--inner' }"
            >
              <template #default="{ row }">
                <span>{{ row.sort }}</span>
              </template>
              <template #edit="{ row }">
                <vxe-input
                  v-model="row.sort"
                  type="text"
                  placeholder="请输入分组"
                ></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="riskType"
              title="风险类型"
              :edit-render="{ autofocus: '.vxe-input--inner' }"
            >
              <template #default="{ row }">
                <span>{{ formatRiskType(row.riskType) }}</span>
              </template>
              <template #edit="{ row }">
                <vxe-select v-model="row.riskType" transfer>
                  <vxe-option
                    v-for="item in riskType"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></vxe-option>
                </vxe-select>
              </template>
            </vxe-column>

            <vxe-column
              field="formula"
              title="计算公式"
              :edit-render="{ autofocus: '.vxe-input--inner' }"
            >
              <template #edit="{ row }">
                <vxe-input
                  v-model="row.formula"
                  type="text"
                  placeholder="请输入计算公式"
                ></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="conditionSyb"
              title="多条件符号"
              :edit-render="{ autofocus: '.vxe-input--inner' }"
            >
              <template #default="{ row }">
                <span>{{ formatConditionSyb(row.conditionSyb) }}</span>
              </template>
              <template #edit="{ row }">
                <vxe-select v-model="row.conditionSyb" transfer>
                  <vxe-option :key="0" :value="0" label="&amp;"></vxe-option>
                  <vxe-option :key="1" :value="1" label="||"></vxe-option>
                </vxe-select>
              </template>
            </vxe-column>
            <vxe-column
              field="jgdgeCondition"
              title="判断条件"
              :edit-render="{ autofocus: '.vxe-input--inner' }"
            >
              <template #edit="{ row }">
                <vxe-input
                  v-model="row.jgdgeCondition"
                  type="text"
                  placeholder="请输入判断条件"
                ></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="sort"
              title="排序"
              :visible="false"
              :edit-render="{ autofocus: '.vxe-input--inner' }"
            >
              <template #edit="{ row }">
                <vxe-input
                  v-model="row.sort"
                  type="number"
                  placeholder="请输入排序"
                ></vxe-input>
              </template>
            </vxe-column>
            <vxe-column
              field="option"
              title="操作"
              :edit-render="{}"
              align="center"
            >
              <template #default="{ row }">
                <a href="javascript:;" @click="handleCopySpecial(row)">复制</a>
              </template>
              <template #edit="{ row }">
                <a href="javascript:;" @click="handleCopySpecial(row)">复制</a>
              </template>
            </vxe-column>
          </vxe-table>

          <div class="cover" v-if="specialFieldsLen != 0">
            <div
              class="spicalTableFields"
              v-for="(item, i) in specialFieldsLen"
              :key="i + 1"
            >
              <a-descriptions
                :title="'风险判断特殊条件字段表' + (i + 1)"
                :column="3"
                style="margin-top: 20px"
              >
              </a-descriptions>
              <div class="btns">
                <a-space>
                  <a-button
                    type="primary"
                    icon="plus"
                    @click="insertEventSpecialField(-1, i)"
                    >新增</a-button
                  >
                  <a-button icon="delete" @click="removeEventSpecialField(i)"
                    >删除选中</a-button
                  >
                </a-space>
              </div>
              <vxe-table
                :ref="'specialFields' + (i + 1)"
                border
                resizable
                show-overflow
                :edit-config="{ trigger: 'click', mode: 'cell' }"
              >
                <vxe-column type="checkbox" width="60"></vxe-column>
                <vxe-column
                  field="forColName"
                  title="序号"
                  width="100"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #default="{ row }">
                    <span>{{ row.forColName }}</span>
                  </template>
                  <template #edit="{ row }">
                    <vxe-input
                      v-model="row.forColName"
                      type="text"
                      placeholder="请输入序号"
                    ></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column
                  field="dateType"
                  title="时间"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #default="{ row }">
                    <span>{{ formatDateType(row.dateType) }}</span>
                  </template>
                  <template #edit="{ row, $rowIndex }">
                    <vxe-select
                      v-model="row.dateType"
                      transfer
                      @change="
                        handleDateTypeChange($rowIndex, $event, 2, row, i)
                      "
                    >
                      <vxe-option :value="0" label="当前时间"></vxe-option>
                      <vxe-option :value="1" label="上一时间"></vxe-option>
                    </vxe-select>
                  </template>
                </vxe-column>
                <vxe-column
                  field="tableCnName"
                  title="数据表"
                  :edit-render="{}"
                >
                  <template #edit="{ row, $rowIndex }">
                    <vxe-select
                      v-model="row.tableCnName"
                      transfer
                      @change="
                        handleTableChangeField($event, row, i, $rowIndex)
                      "
                    >
                      <vxe-option
                        v-for="item in tableListFields[i][$rowIndex]"
                        :key="item.tableCnName"
                        :value="item.tableCnName"
                        :label="item.tableCnName"
                      ></vxe-option>
                    </vxe-select>
                  </template>
                </vxe-column>
                <vxe-column
                  field="tableEnName"
                  title="表英文名"
                  :visible="false"
                >
                </vxe-column>
                <vxe-column
                  field="colCnName"
                  title="对应列"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #edit="{ row, $rowIndex }">
                    <vxe-select
                      v-model="row.colCnName"
                      transfer
                      @change="handleColChangeField($event, row, i, $rowIndex)"
                    >
                      <vxe-option
                        v-for="item in colLists[i][$rowIndex]"
                        :key="item.colCnName"
                        :value="item.colCnName"
                        :label="item.colCnName"
                      ></vxe-option>
                    </vxe-select>
                  </template>
                </vxe-column>
                <vxe-column field="colEnName" title="列英文名" :visible="false">
                </vxe-column>
                <vxe-column
                  field="queryVal"
                  title="查询列值"
                  :edit-render="{ autofocus: '.vxe-input--inner' }"
                >
                  <template #edit="{ row }">
                    <vxe-input
                      v-model="row.queryVal"
                      type="text"
                      placeholder="请输入查询列值"
                    ></vxe-input>
                  </template>
                </vxe-column>
                <vxe-column field="tabId" title="tabId" :visible="false">
                </vxe-column>
                <vxe-column field="sort" title="排序" :visible="false">
                </vxe-column>
                <vxe-column
                  field="option"
                  title="操作"
                  :edit-render="{}"
                  align="center"
                >
                  <template #default="{ row }">
                    <a
                      href="javascript:;"
                      @click="handleCopySpecialFields(row, i)"
                      >复制</a
                    >
                  </template>
                  <template #edit="{ row }">
                    <a
                      href="javascript:;"
                      @click="handleCopySpecialFields(row, i)"
                      >复制</a
                    >
                  </template>
                </vxe-column>
              </vxe-table>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>
<script>
import {
  getTabdetail,
  getTabCol,
  getRiskdetail,
  createRiskRule,
  updateRiskrule,
} from "@/api/risk";
import {
  indexStandardType,
  taxpayerNature,
  dataType,
  indexLevel,
  riskType,
} from "@/utils/dict";
import { getLabel, number_to_word } from "@/utils/utils";
// import pick from "lodash.pick";
import _ from "lodash";
export default {
  name: "ruleModal",
  data() {
    return {
      tableData: [],
      riskTableData: [],
      riskTableDataSpecial: [],
      status: 1,
      visible: false,
      showSpecial: false,
      record: {},
      form: this.$form.createForm(this, { name: "form" }),
      confirmLoading: false,
      pid: "",
      parents: [],
      colTypes: [],
      indexLevel,
      riskType,
      tableList: [],
      tableListFields: [],
      colList: [],
      colLists: [],
      dateType: "",
      specialFieldsLen: 0,
      catalogMenuVo: {},
      calRuleCatalogVo: {},
      calRuleMainVo: {},
    };
  },
  methods: {
    number_to_word,
    handleDateTypeChange(rowIndex, val, type, row, index) {
      row.tableCnName = "";
      row.tableEnName = "";
      row.colCnName = "";
      row.colEnName = "";
      if (type == 1) {
        this.colList[rowIndex] = [];
      } else {
        this.colLists[index][rowIndex] = [];
      }
      let dateType = val.value;
      this.getTabdetail(row.sort - 1, this.parents, dateType, type, index);
    },
    show(status, pid, parents, detailId) {
      this.pid = pid;
      this.parents = parents;
      this.status = status;
      this.visible = true;
      let parentsArr = [];
      parents.forEach((item) => {
        parentsArr.push(item.menuName);
      });
      let parentsStr = parentsArr.reverse().join("/");
      this.dateType = dataType.filter(
        (item) => item.label == parents[0].menuName
      )[0].value;
      this.$nextTick(() => {
        this.form.setFieldsValue({ cata: parentsStr });
      });
      if (status == 3) {
        this.getRiskdetail(detailId);
      }
    },
    async getRiskdetail(detailId) {
      let res = await getRiskdetail({ id: detailId });
      if (res.status == 200) {
        let calRuleMainVo = res.data.calRuleMainVo;
        let calRuleTabDetailVos = res.data.calRuleTabDetailVos;
        let calRuleRiskRangeVos = res.data.calRuleRiskRangeVos;
        let specialTimeVo = res.data.specialTimeVo;
        let calRuleSpecialTabVos = res.data.calRuleSpecialTabVos;
        this.catalogMenuVo = res.data.catalogMenuVo;
        this.calRuleCatalogVo = res.data.calRuleCatalogVo;
        this.calRuleMainVo = res.data.calRuleMainVo;
        this.form.setFieldsValue(
          Object.assign({}, calRuleMainVo, specialTimeVo)
        );
        this.$refs.xTable.loadData(calRuleTabDetailVos);
        this.$refs.xTableRisk.loadData(calRuleRiskRangeVos);
        let len = calRuleSpecialTabVos.length;
        if (len != 0) {
          this.showSpecial = true;
          this.specialFieldsLen = len;
          this.$nextTick(() => {
            this.$refs.xTableSpecial.loadData(calRuleSpecialTabVos);
            for (let i = 0; i < len; i++) {
              let item = calRuleSpecialTabVos[i];
              this.$refs["specialFields" + (i + 1)][0].loadData(
                item.calRuleSpecialTabDetailVos
              );
              this.tableListFields[i] = [];
              this.colLists[i] = [];
            }
          });
        }
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    handleSave: _.debounce(function () {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          let formula = values.formula;
          let params;
          let calRuleMainVo = {
            indexName: values.indexName,
            indexLevel: values.indexLevel,
            formula: formula,
            noRiskDesc: values.noRiskDesc,
            lowRiskDesc: values.lowRiskDesc,
            midRiskDesc: values.midRiskDesc,
            higRiskDesc: values.higRiskDesc,
            riskTreatment: values.riskTreatment,
            riskTrend: values.riskTrend,
            riskWarning: values.riskWarning,
          };
          let catalogMenuVo = {
            menuName: values.indexName,
            parentId: this.pid,
            type: 8,
          };

          let parents = this.parents;
          let len = parents.length;
          let calRuleCatalogVo = {
            year: len == 5 ? parents[3].menuName : parents[2].menuName,
            taxpayerNature:
              len == 5
                ? getLabel(
                    parents[2].menuName,
                    taxpayerNature,
                    "label",
                    "value"
                  )
                : -2,
            indexStandardType:
              len == 5
                ? getLabel(
                    parents[1].menuName,
                    indexStandardType,
                    "label",
                    "value"
                  )
                : -2,
            dateType: getLabel(parents[0].menuName, dataType, "label", "value"),
          };
          let tableData = this.$refs.xTable.getTableData().tableData;
          let riskData = this.$refs.xTableRisk.getTableData().tableData;
          let calRuleSpecialTabVos;
          if (this.showSpecial) {
            let specialData = JSON.parse(
              JSON.stringify(this.$refs.xTableSpecial.getTableData().tableData)
            );
            calRuleSpecialTabVos = specialData;
            let len1 = calRuleSpecialTabVos.length;
            for (let i = 0; i < len1; i++) {
              let item = calRuleSpecialTabVos[i];
              item.calRuleSpecialTabDetailVos =
                this.$refs[
                  "specialFields" + (i + 1)
                ][0].getTableData().tableData;
            }
          }
          let calRuleTabDetailVos = tableData;
          let calRuleRiskRangeVos = riskData;

          let specialTimeVo = {
            beginDate: values.beginDate,
            dateType: this.dateType,
            endDate: values.endDate,
            type: 2,
            year: len == 5 ? parents[3].menuName : parents[2].menuName,
          };
          params = {
            calRuleMainVo,
            catalogMenuVo,
            calRuleCatalogVo,
            calRuleTabDetailVos,
            calRuleRiskRangeVos,
          };
          if (this.showSpecial) {
            params.calRuleSpecialTabVos = calRuleSpecialTabVos;
          }
          if (this.dateType != 0 && values.beginDate && values.endDate) {
            params.specialTimeVo = specialTimeVo;
          }
          let res;
          if (this.status == 3) {
            params.catalogMenuVo = Object.assign(
              {},
              this.catalogMenuVo,
              catalogMenuVo
            );
            params.calRuleCatalogVo = Object.assign(
              {},
              this.calRuleCatalogVo,
              calRuleCatalogVo
            );
            params.calRuleMainVo = Object.assign(
              {},
              this.calRuleMainVo,
              calRuleMainVo
            );
            res = await updateRiskrule(params);
          } else {
            res = await createRiskRule(params);
          }
          if (res.status == 200) {
            this.$emit("reload");
            this.$message.success(
              this.status == 3 ? "修改成功！" : "新增成功！"
            );
            this.handleCancel();
          } else {
            this.$notification.error({
              message: "系统提示",
              description: res.msg || res.message,
              duration: 4,
            });
          }
        }
      });
    }, 300),
    handleCancel() {
      this.tableData = [];
      this.riskTableData = [];
      this.riskTableDataSpecial = [];
      this.showSpecial = false;
      this.specialFieldsLen = 0;
      this.form.resetFields();
      this.visible = false;
    },
    async getTabdetail(rowIndex, parents, dateType, type, index) {
      let len = parents.length;
      let params = {
        companyField: "通用",
        dateType: getLabel(parents[0].menuName, dataType, "label", "value"),
        indexStandardType:
          len == 5
            ? getLabel(parents[1].menuName, indexStandardType, "label", "value")
            : -2,
        taxpayerNature:
          len == 5
            ? getLabel(parents[2].menuName, taxpayerNature, "label", "value")
            : -2,
        year: len == 5 ? parents[3].menuName : parents[2].menuName,
      };
      if (
        dateType == 1 &&
        getLabel(parents[0].menuName, dataType, "label", "value") == 0
      ) {
        params.year--;
      }
      let res = await getTabdetail(params);
      if (res.status == 200) {
        if (type == 1) {
          this.tableList[rowIndex] = res.data;
        } else {
          this.tableListFields[index][rowIndex] = res.data;
        }
      } else {
        if (type == 1) {
          this.tableList[rowIndex] = [];
        } else {
          this.tableListFields[index][rowIndex] = [];
        }
      }
    },
    async handleTableChange(val, row, rowIndex) {
      let tableEnName = this.tableList[rowIndex].filter(
        (item) => item.tableCnName == val.value
      )[0].tableEnName;
      let tableId = this.tableList[rowIndex].filter(
        (item) => item.tableCnName == val.value
      )[0].tableId;
      row.tableEnName = tableEnName;
      row.tabId = tableId;
      row.colCnName = "";
      row.colEnName = "";
      let params = {
        enName: tableEnName,
      };
      let res = await getTabCol(params);
      if (res.status == 200) {
        let cols = res.data.colListVos;
        this.colList[rowIndex] = cols;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    handleColChange(val, row, rowIndex) {
      let colEnName;
      colEnName = this.colList[rowIndex].filter(
        (item) => item.colCnName == val.value
      )[0].colEnName;

      row.colEnName = colEnName;
    },

    async handleTableChangeField(val, row, index, rowIndex) {
      let tableEnName = this.tableListFields[index][rowIndex].filter(
        (item) => item.tableCnName == val.value
      )[0].tableEnName;
      let tableId = this.tableListFields[index][rowIndex].filter(
        (item) => item.tableCnName == val.value
      )[0].tableId;
      row.tableEnName = tableEnName;
      row.tabId = tableId;
      row.colCnName = "";
      row.colEnName = "";
      let params = {
        enName: tableEnName,
      };
      let res = await getTabCol(params);
      if (res.status == 200) {
        let cols = res.data.colListVos;
        this.colLists[index][rowIndex] = cols;
      } else {
        let msg = res.msg || res.message;
        let len = msg.length;
        this.$notification.error({
          message: "系统提示",
          description: len > 100 ? "系统错误，请联系管理员" : msg,
          duration: 4,
        });
      }
    },
    handleColChangeField(val, row, index, rowIndex) {
      let colEnName;
      colEnName = this.colLists[index][rowIndex].filter(
        (item) => item.colCnName == val.value
      )[0].colEnName;
      row.colEnName = colEnName;
    },

    /* 
      字段信息
    */

    // 插入校验
    async insertEvent(row) {
      let len = this.$refs.xTable.getTableData().tableData.length;
      this.$refs.xTable
        .insertAt(
          { sort: len + 1, forColName: number_to_word(len + 1), dateType: 0 },
          row
        )
        .then(({ row }) => this.$refs.xTable.setActiveRow(row));
      this.getTabdetail(len, this.parents, 0, 1);
    },
    removeEvent() {
      this.$refs.xTable.removeCheckboxRow();
    },
    handleCopy(row) {
      let len = this.$refs.xTable.getTableData().tableData.length;
      this.$refs.xTable
        .insertAt(
          Object.assign({}, row, { forColName: number_to_word(len + 1) }),
          -1
        )
        .then(({ row }) => this.$refs.xTable.setActiveRow(row));
    },
    formatRiskType(value) {
      if (value !== null) {
        return riskType.filter((item) => item.value == value)[0].label;
      }
      return "";
    },
    formatConditionSyb(value) {
      if (value === 1) {
        return "||";
      } else if (value == 0) {
        return "&";
      }
    },
    formatDateType(value) {
      if (value !== null) {
        if (value == 0) {
          return "当前时间";
        }
        return "上一时间";
      }
      return value;
    },
    formatIndexTime(value) {
      if (value !== null) {
        if (value == 0) {
          return "当前";
        }
        return "上期";
      }
      return value;
    },
    /* 
      风险判断信息
    */

    // 插入校验
    async insertEventRisk(row) {
      this.$refs.xTableRisk
        .insertAt({ riskType: 0 }, row)
        .then(({ row }) => this.$refs.xTableRisk.setActiveRow(row));
    },
    removeEventRisk() {
      this.$refs.xTableRisk.removeCheckboxRow();
    },
    handleCopyRisk(row) {
      this.$refs.xTableRisk
        .insertAt(row, -1)
        .then(({ row }) => this.$refs.xTableRisk.setActiveRow(row));
    },

    /* 
      风险判断特殊条件
    */

    // 插入校验
    async insertEventSpecial(row) {
      let len = this.$refs.xTableSpecial.getTableData().tableData.length;
      this.$refs.xTableSpecial
        .insertAt({ sort: len + 1, riskType: 0 }, row)
        .then(({ row }) => this.$refs.xTableSpecial.setActiveRow(row));
      this.specialFieldsLen =
        this.$refs.xTableSpecial.getTableData().tableData.length;
    },
    removeEventSpecial() {
      this.$refs.xTableSpecial.removeCheckboxRow();
      this.specialFieldsLen =
        this.$refs.xTableSpecial.getTableData().tableData.length;
    },
    handleCopySpecial(row) {
      this.$refs.xTableSpecial
        .insertAt(row, -1)
        .then(({ row }) => this.$refs.xTableSpecial.setActiveRow(row));
      this.specialFieldsLen =
        this.$refs.xTableSpecial.getTableData().tableData.length;
    },

    /* 
      风险判断特殊条件字段表
    */

    // 插入校验
    async insertEventSpecialField(row, index) {
      let len =
        this.$refs["specialFields" + (index + 1)][0].getTableData().tableData
          .length;
      this.$refs["specialFields" + (index + 1)][0]
        .insertAt(
          { sort: len + 1, forColName: number_to_word(len + 1), dateType: 0 },
          row
        )
        .then(({ row }) =>
          this.$refs["specialFields" + (index + 1)][0].setActiveRow(row)
        );
      if (!this.tableListFields[index]) {
        this.tableListFields[index] = [];
        this.colLists[index] = [];
      }
      this.getTabdetail(len, this.parents, 0, 2, index);
    },
    removeEventSpecialField(index) {
      this.$refs["specialFields" + (index + 1)][0].removeCheckboxRow();
    },
    handleCopySpecialFields(row, index) {
      let len =
        this.$refs["specialFields" + (index + 1)][0].getTableData().tableData
          .length;
      this.$refs["specialFields" + (index + 1)][0]
        .insertAt(
          Object.assign({}, row, { forColName: number_to_word(len + 1) }),
          -1
        )
        .then(({ row }) =>
          this.$refs["specialFields" + (index + 1)][0].setActiveRow(row)
        );
    },
  },
  computed: {
    title() {
      let title;
      switch (this.status) {
        case 2:
          title = "新建风险分析指标";
          break;
        case 3:
          title = "编辑";
          break;
      }
      return title;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: space-between;
}
.btns {
  margin-bottom: 10px;
}
.btn {
  cursor: pointer;
}
</style>
